var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isVideo ? _c('video', {
    ref: "video",
    attrs: {
      "src": _vm.src,
      "controls": "",
      "controlsList": "nodownload"
    },
    on: {
      "click": _vm.open
    }
  }) : _c('img', {
    staticClass: "image",
    attrs: {
      "src": _vm.src
    },
    on: {
      "click": _vm.open
    }
  }), _c('div', {
    attrs: {
      "hidden": ""
    }
  }, [_c('b-form-file', {
    ref: "fileInput",
    attrs: {
      "accept": _vm.isVideo ? 'video/*' : 'image/*'
    },
    on: {
      "input": _vm.change
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }