





















import ToastMixin from '@/mixins/ToastMixin';
import ToastVariant from '@/model/toast/toast-variant';
import { mixins } from 'vue-class-component';
import { Component, Emit, Prop, Ref, Watch } from 'vue-property-decorator';

@Component
export default class FormMedia extends mixins(ToastMixin) {
    @Prop({ type: String })
    asset!: string;

    @Prop({ type: Boolean, default: false })
    isVideo!: boolean;

    @Ref() readonly video!: HTMLVideoElement;

    // eslint-disable-next-line global-require
    defaultImage = require('@/assets/logo/welcome_logo.png');

    src = '';

    @Watch('asset', { immediate: true })
    onChangeAsset(): void {
        this.src = this.asset ?? this.defaultImage;
    }

    open(): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (this.$refs.fileInput as any).$el.childNodes[0].click();
    }

    public createImage(): string {
        if (this.video) {
            const canvas = document.createElement('canvas');
            canvas.height = this.video.videoHeight;
            canvas.width = this.video.videoWidth;
            canvas
                .getContext('2d')
                ?.drawImage(
                    this.video,
                    0,
                    0,
                    this.video.videoWidth,
                    this.video.videoHeight,
                );
            return canvas.toDataURL('image/jpeg');
        }

        return '';
    }

    isImageFile(file: File): boolean {
        return file?.type?.split('/')?.[0] === 'image';
    }

    isVideoFile(file: File): boolean {
        return file?.type?.split('/')?.[0] === 'video';
    }

    @Emit()
    onChange(value: File): File {
        return value;
    }

    change(value: File): void {
        if (
            (this.isVideo && this.isVideoFile(value)) ||
            (!this.isVideo && this.isImageFile(value))
        ) {
            this.src = URL.createObjectURL(value);
            this.onChange(value);
        } else if (value) {
            this.showToast(
                this.$t('how-to.invalid-file').toString(),
                this.$t('error-title').toString(),
                ToastVariant.DANGER,
            );
        }
    }
}
